import { useFormContext } from "react-hook-form";

import { CodeVerificationIcon } from "src/krispcall/assets";
import OtpInputField from "src/krispcall/components/molecules/fields/v3/OtpInputField";
import Button from "src/krispcall/components/atoms/button/v3/Button";

import { LoginVerificationWrapper } from "../Styles";

const MfaBackupCodeForm = ({recover, loading}) => {

    const { watch, setError } = useFormContext();
    const formValues = watch();

    const verifyBackupCode = () => {
        if (formValues?.emailCode?.length !== 8) {
            setError("emailCode", { type: "custom", message: "Please enter valid code." });
            return;
        }
        recover(formValues.emailCode);
    };

    return (
        <LoginVerificationWrapper className="w-[400px]">
            <div className="signup-icon-circle"><img src={CodeVerificationIcon} /></div>
            <div className="flex flex-col mb-4">
                <span className="login-verificaiton-title">2 step verification</span>
                <span className="login-verificaiton-subtitle">Please enter one of the backup code.</span>

                <OtpInputField
                    name="emailCode"
                    id="emailCode"
                    containerStyle="my-8"
                    autoFocus
                    numInputs={8}
                    placeholder="00000000"
                    onPressEnter={verifyBackupCode}
                />

                <Button
                    loading={loading}
                    block
                    variant="primary"
                    onClick={verifyBackupCode}
                >
                    Verify
                </Button>
            </div>
        </LoginVerificationWrapper>
    );
};
export default MfaBackupCodeForm;
