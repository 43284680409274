// copied from MfaAuthVerifyFormCustom

import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { EmailMfaType, RecoveryMfaType, SmsMfaType, TotpMfaType } from "src/auth/MfaSetupPage";
import { mfaAuth } from "src/auth/mfa/MfaVerifyForm";
import { CodeVerificationIcon } from "src/krispcall/assets";
import { ActionModal } from "src/krispcall/components/organisms/modals/actions/ActionModal";
import { useChatBot } from "src/krispcall/hooks/useChatBot";

import * as AuthBackend from "src/auth/AuthBackend";

import MfaVerifySmsForm from "./MfaVerifySmsFormCustom";
import MfaVerifyTotpFormCustom from "./MfaVerifyTotpFormCustom";
import { VerificationWrapper } from "../Styles";
import VerificationTypeCard from "./VerificaitonTypeCard";
import MfaBackupCodeForm from "./MfaBackupCodeForm";

export const NextMfa = "NextMfa";
export const RequiredMfa = "RequiredMfa";

export function MfaAuthVerifyFormCustom({formValues, authParams, mfaProps, application, onSuccess, onFail, state, setEmailOtpCode, updateFormValues}) {
  formValues.password = "";
  formValues.username = "";
  const [loading, setLoading] = useState(false);
  const [mfaType, setMfaType] = useState();
  const [isOtherMethodOpen, setIsOtherMethodOpen] = useState(false);
  const openOtherMethodModal = () => setIsOtherMethodOpen(true);
  const closeOtherMethodModal = () => setIsOtherMethodOpen(false);
  const { setError, setValue, clearErrors } = useFormContext();
  const { openChat } = useChatBot();
  const [showAnotherMethods, setShowAnotherMethods] = useState(false);
  const showBackupCodes = mfaProps?.some(item => item.enabled === true && item.mfaType === TotpMfaType);
  const verify = ({ passcode }) => {
    if (!passcode || passcode.length < 6) {
      setError("emailCode", { type: "custom", message: "Please Enter OTP code" });
      return;
    }
    setLoading(true);
    const values = {...formValues, passcode, mfaType: mfaType?.mfaType};
    const loginFunction = formValues.type === "cas" ? AuthBackend.loginCas : AuthBackend.login;
    loginFunction(values, authParams).then((res) => {
      if (res.status === "ok") {
        onSuccess(res);
      } else {
        setError("emailCode", { type: "custom", message: "Incorrect code, please try again." });
        onFail(res.msg);
      }
    }).catch((res) => {
      onFail(res.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  const recover = (code) => {
    setLoading(true);
    const values = {...formValues, recoveryCode: code};
    const loginFunction = formValues.type === "cas" ? AuthBackend.loginCas : AuthBackend.login;
    loginFunction(values, authParams).then((res) => {
      if (res.status === "ok") {
        onSuccess(res);
      } else {
        setError("emailCode", { type: "custom", message: "Incorrect code, please try again." });
        onFail(res.msg);
      }
    }).catch((res) => {
      onFail(res.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  const visibleOtherMfaTypeModal = (preferredMfa) => {
    const appMfa = mfaProps.find(
        (item) => item.mfaType === TotpMfaType
    );
    const smsMfa = mfaProps.find(
        (item) => item.mfaType === SmsMfaType
    );
    const isEmailMfaEnabled = preferredMfa.mfaType === EmailMfaType && (appMfa.enabled || smsMfa.enabled);
    const isSmsMfaEnabled = preferredMfa.mfaType === SmsMfaType && appMfa.enabled;
    const isTotpMfaEnabled = preferredMfa.mfaType === TotpMfaType;

    setShowAnotherMethods(isEmailMfaEnabled || isSmsMfaEnabled || isTotpMfaEnabled);
  };

  useEffect(() => {
    const preferredMfa = mfaProps.find(
      (item) => item.enabled === true && item.isPreferred === true
    );
    setMfaType(preferredMfa);
    visibleOtherMfaTypeModal(preferredMfa);
  }, []);

  const changeMfaType = (newMfaType) => {
    clearErrors();
    if (newMfaType === RecoveryMfaType) {
      setMfaType({
        "enabled": true,
        "isPreferred": false,
        "mfaType": RecoveryMfaType,
      });
      setValue("emailCode", "");
      closeOtherMethodModal();
    } else {
      const preferredMfa = mfaProps.find(
        (item) => item.enabled === true && item.mfaType === newMfaType
      );
      setValue("emailCode", "");
      setMfaType(preferredMfa);
      visibleOtherMfaTypeModal(preferredMfa);
      closeOtherMethodModal();
    }
  };

  const otherMethod = () => {
    return (
      <>
        {
          showAnotherMethods &&
          <span className="text-link-300 text-15 cursor-pointer" onClick={openOtherMethodModal}>Try other methods</span>
        }
        <ActionModal
          title="Select authentication method"
          open={isOtherMethodOpen}
          width={400}
          onCancel={closeOtherMethodModal}
          footer={null}
        >
          <VerificationWrapper>
            <span className="verificaiton-modal-sub-title">Choose how you want to sign in</span>
            {
              mfaProps?.map((item, key) => item.enabled === true && // pull only enabled mfa
              item.mfaType !== EmailMfaType && // don't show email mfa even if backend sends
              item.mfaType !== mfaType?.mfaType && // don't show in list if already selected
              (
                <VerificationTypeCard changeMfaType={changeMfaType} key={key} item={item} />
              ))
            }
            {
              // show backup code only if auth app is enabled
              showBackupCodes && mfaType?.mfaType !== RecoveryMfaType &&
              <div className="verification-box" onClick={() => changeMfaType(RecoveryMfaType)}>
                  <img src={CodeVerificationIcon} />
                  <div className="flex flex-col">
                    <span className="verification-box-title">Backup Code</span>
                    <span>Use one of your backup code</span>
                  </div>
              </div>
            }
            <span className="footer-text">Still having trouble? <p className="text-link-300 cursor-pointer" onClick={() => openChat()}> Contact Support</p></span>
          </VerificationWrapper>
        </ActionModal>
      </>
    );
  };

  if (mfaType?.mfaType !== RecoveryMfaType) {
    return (
      <div>
        {mfaType?.mfaType === SmsMfaType || mfaType?.mfaType === EmailMfaType ? (
          <MfaVerifySmsForm
            mfaProps={mfaType}
            method={mfaAuth}
            onFinish={verify}
            application={application}
            state={state}
            setEmailOtpCode={setEmailOtpCode}
            updateFormValues={updateFormValues}
          />
        ) : (
          <>
            <MfaVerifyTotpFormCustom
              mfaProps={mfaProps}
              onFinish={verify}
              state={state}
              setAuthOtpCode={setEmailOtpCode}
              updateFormValues={updateFormValues}
              loading={loading}
              />
          </>
        )}
        {
          otherMethod()
        }
      </div>
    );
  } else {
    return (
      <>
        <MfaBackupCodeForm
          recover={recover}
          loading={loading}
        />
        {otherMethod()}
      </>
    );
  }
}
