import { Select } from "antd";

import { StaticBaseUrl } from "src/Setting";

const { Option } = Select;

const renderOptionLabel = (option) => {
    return (
      <div style={{backgroundColor: "#F5F4F6"}}>
        <img src={option.image} alt={option.label} style={{ width: "20px" }} />
        {/* {option.label} */}
      </div>
    );
};

export function getCountryCodeOption(country) {
  return (
    <Option key={country.code} value={country.code} label={renderOptionLabel({ image: `${StaticBaseUrl}/flag-icons/${country.code}.svg`, label: `+${country.phone}` })} text={`${country.name}, ${country.code}, ${country.phone}`} >
      <div style={{display: "flex", justifyContent: "space-between", marginRight: "10px"}}>
        <div>
          {getCountryImage(country)}
          {`${country.name}`}
        </div>
        {`+${country.phone}`}
      </div>
    </Option>
  );
}

export function getCountryImage(country) {
  return <img src={`${StaticBaseUrl}/flag-icons/${country.code}.svg`} alt={country.name} height={20} style={{marginRight: 10}} />;
}

export function isValidName(name, type) {
  // Regular expression to check for only alphabetic characters
  const regex = /^[A-Za-z ]+$/;

  // Check if name length is at least 2 and matches the regex
  if (!name?.length >= 2) {
    return {
      status: false,
      error: `Please input valid ${type}`,
    };
  }

  if (!regex.test(name)) {
    return {
      status: false,
      error: "Shouldn't contain numeric or special characters",
    };
  }

  return {
    status: true,
    error: "Success",
  };
}

export function expireCookie(name, path = "/", domain) {
    let cookieString = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=" + path + ";";
    if (domain) {
        cookieString += "domain=" + domain + ";";
    }
    document.cookie = cookieString;
}

export const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    // Pad with leading zero for single-digit minutes/seconds
    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };
