// Copied from LoginPage for customization

import React, { Suspense, lazy } from "react";

import { Col, Form, Input, Result, Spin, Tabs } from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {withRouter} from "react-router-dom";
import i18next from "i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import OrganizationSelect from "src/common/select/OrganizationSelect";
import SelfLoginButton from "src/auth/SelfLoginButton";
import CustomGithubCorner from "src/common/CustomGithubCorner";
import { SendCodeInput } from "src/common/SendCodeInput";
import { CaptchaRule } from "src/common/modal/CaptchaModal";
import RedirectForm from "src/common/RedirectForm";
import { GoogleOneTapLoginVirtualButton } from "src/auth/GoogleLoginButton";
import LanguageSelect from "src/common/select/LanguageSelect";
import { BACK_LINKS } from "src/krispcall/constants/hyper-links";
import { createMarkup } from "src/krispcall/utils/markup";
const FaceRecognitionModal = lazy(() => import("src/common/modal/FaceRecognitionModal"));
import { CustomThemeContextProvider } from "src/krispcall/context/CustomThemeContext";
import Button from "src/krispcall/components/atoms/button/v3/Button";

import * as UserWebauthnBackend from "src/backend/UserWebauthnBackend";
import * as AuthBackend from "src/auth/AuthBackend";
import * as OrganizationBackend from "src/backend/OrganizationBackend";
import * as ApplicationBackend from "src/backend/ApplicationBackend";
import * as Provider from "src/auth/Provider";
import * as ProviderButtonCustom from "src/krispcall/components/organisms/provider-button/ProviderButtonCustom";
import * as Util from "src/auth/Util";
import * as Setting from "src/Setting";

import { Text } from "../../atoms/";
import { Checkbox, TopLabelInput } from "../../molecules/fields";
import { CaptchaModalCustom, RecapthcaV2 } from "../../organisms";
import { MfaAuthVerifyFormCustom, NextMfa, RequiredMfa } from "./common/MfaAuthVerifyFormCustom";
import { CrispChat } from "../../organisms";
import { useGetRecaptchaV3Token } from "../../organisms/recaptcha/useGetRecaptchaV3Token";
import { SignupLeftWrapper } from "../signup/Styles";
import SigninFormWrapper from "./common/SigninFormWrapper";
import { InputField, PasswordField } from "../../molecules/fields/v3";

class SigninPageCustom extends React.Component {
  constructor(props) {
    super(props);
    this.updateFormValuesRef = this.updateFormValues.bind(this);
    this.setEmailOtpCodeRef = this.setEmailOtpCode.bind(this);
    this.onFinishRef = this.onFinish.bind(this);
    this.state = {
      classes: props,
      type: props.type,
      applicationName: props.applicationName ?? (props.match?.params?.applicationName ?? null),
      owner: props.owner ?? (props.match?.params?.owner ?? null),
      mode: props.mode ?? (props.match?.params?.mode ?? null), // "signup" or "signin"
      msg: null,
      username: null,
      validEmailOrPhone: false,
      validEmail: false,
      enableCaptchaModal: CaptchaRule.None,
      openCaptchaModal: false,
      openFaceRecognitionModal: false,
      verifyCaptcha: undefined,
      samlResponse: "",
      relayState: "",
      redirectUrl: "",
      isTermsOfUseVisible: false,
      termsOfUseContent: "",
      orgChoiceMode: new URLSearchParams(props.location?.search).get("orgChoiceMode") ?? null,
      captchaType: "",
      captchaSiteKey: "",
      captchaToken: "",
      clientSecret: "",
      captchaVerified: "",
      current: 0,
      code: "",
      submitLoading: false,
      autoSignin: true,
      isValid: false,
    };

    if (this.state.type === "cas" && props.match?.params.casApplicationName !== undefined) {
      this.state.owner = props.match?.params?.owner;
      this.state.applicationName = props.match?.params?.casApplicationName;
    }

    this.form = React.createRef();
    this.setStateRef = this.setState.bind(this);
  }

  componentDidMount() {
    const oAuthParams = Util.getOAuthGetParameters();

    if (oAuthParams) {
      localStorage.setItem("params", btoa(JSON.stringify(oAuthParams)));
    }
    if (this.getApplicationObj() === undefined) {
      if (this.state.type === "login" || this.state.type === "saml") {
        this.getApplication();
      } else if (this.state.type === "code" || this.state.type === "cas") {
        this.getApplicationLogin();
      } else {
        Setting.showMessage("error", `Unknown authentication type: ${this.state.type}`);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.loginMethod === undefined && this.state.loginMethod === undefined) {
      const application = this.getApplicationObj();
      this.setState({ loginMethod: this.getDefaultLoginMethod(application) });
    }

    const captchaProviderItems = this.getCaptchaProviderItems(this.props.application);
    if (captchaProviderItems?.length !== 0) {
      if (prevProps.application !== this.props.application || (captchaProviderItems && this.state.enableCaptchaModal !== captchaProviderItems[0].rule)) {
        this.setState({loginMethod: this.getDefaultLoginMethod(this.props.application)});
        if (captchaProviderItems) {
          if (captchaProviderItems.some(providerItem => providerItem.rule === "Always")) {
            this.setState({enableCaptchaModal: CaptchaRule.Always});
          } else if (captchaProviderItems.some(providerItem => providerItem.rule === "Dynamic")) {
            this.setState({enableCaptchaModal: CaptchaRule.Dynamic});
          } else {
            this.setState({enableCaptchaModal: CaptchaRule.None});
          }
        }
      }
    }

    if (prevProps.account !== this.props.account && this.props.account !== undefined) {
      if (this.props.account && this.props.account.owner === this.props.application?.organization) {
        const params = new URLSearchParams(this.props.location.search);
        const silentSignin = params.get("silentSignin");
        if (silentSignin !== null) {
          this.sendSilentSigninData("signing-in");

          const values = {};
          values["application"] = this.props.application.name;
          this.login(values);
        }

        if (params.get("popup") === "1") {
          window.addEventListener("beforeunload", () => {
            this.sendPopupData({type: "windowClosed"}, params.get("redirect_uri"));
          });
        }

        if (this.props.application.enableAutoSignin) {
          const values = {};
          values["application"] = this.props.application.name;
          this.login(values);
        }
      }
    }
  }

  checkCaptchaStatus(values) {
    AuthBackend.getCaptchaStatus(values)
      .then((res) => {
        if (res.status === "ok") {
          if (res.data) {
            this.setState({
              openCaptchaModal: true,
              values: values,
            });
            return null;
          }
        }
        this.login(values);
      });
  }

  getApplicationLogin() {
    const loginParams = (this.state.type === "cas") ? Util.getCasLoginParameters("admin", this.state.applicationName) : (Util.getOAuthGetParameters() ? Util.getOAuthGetParameters() : JSON.parse(atob(localStorage.getItem("params"))));
    AuthBackend.getApplicationLogin(loginParams)
      .then((res) => {
        if (res.status === "ok") {
          const application = res.data;
          this.onUpdateApplication(application);
        } else {
          this.onUpdateApplication(null);
          this.setState({
            msg: res.msg,
          });
        }
      });
  }

  getApplication() {
    if (this.state.applicationName === null) {
      return null;
    }

    if (this.state.owner === null || this.state.type === "saml") {
      ApplicationBackend.getApplication("admin", this.state.applicationName)
        .then((res) => {
          if (res.status === "error") {
            this.onUpdateApplication(null);
            this.setState({
              msg: res.msg,
            });
            return ;
          }
          this.onUpdateApplication(res.data);
        });
    } else {
      OrganizationBackend.getDefaultApplication("admin", this.state.owner)
        .then((res) => {
          if (res.status === "ok") {
            const application = res.data;
            this.onUpdateApplication(application);
            this.setState({
              applicationName: res.data.name,
            });
            // if we don't have url params then redirect to client
            const oAuthParams = Util.getOAuthGetParameters();
            if (!oAuthParams && !localStorage.getItem("params")) {
              Setting.showMessage("error", "Sesion Expired! Couldn't find Application!");
              window.location.href = res.data.homepageUrl;
            }
          } else {
            this.onUpdateApplication(null);
            Setting.showMessage("error", res.msg);

            this.props.history.push("/404");
          }
        });
    }
  }

  getApplicationObj() {
    return this.props.application;
  }

  getDefaultLoginMethod(application) {
    if (application?.signinMethods?.length > 0) {
      switch (application?.signinMethods[0].name) {
      case "Password": return "password";
      case "Verification code": {
        switch (application?.signinMethods[0].rule) {
        case "All": return "verificationCode"; // All
        case "Email only": return "verificationCodeEmail";
        case "Phone only": return "verificationCodePhone";
        }
        break;
      }
      case "WebAuthn": return "webAuthn";
      case "LDAP": return "ldap";
      case "Face ID": return "faceId";
      }
    }

    return "password";
  }

  getPlaceholder() {
    switch (this.state.loginMethod) {
    case "verificationCode": return i18next.t("login:Email or phone");
    case "verificationCodeEmail": return i18next.t("login:Email");
    case "verificationCodePhone": return i18next.t("login:Phone");
    case "ldap": return i18next.t("login:LDAP username, Email or phone");
    default: return i18next.t("login:username, Email or phone");
    }
  }

  onUpdateAccount(account) {
    this.props.onUpdateAccount(account);
  }

  onUpdateApplication(application) {
    this.props.onUpdateApplication(application);
  }

  parseOffset(offset) {
    if (offset === 2 || offset === 4 || Setting.inIframe() || Setting.isMobile()) {
      return "0 auto";
    }
    if (offset === 1) {
      return "0 10%";
    }
    if (offset === 3) {
      return "0 60%";
    }
  }

  populateOauthValues(values) {
    if (this.getApplicationObj()?.organization) {
      values["organization"] = this.getApplicationObj().organization;
    }

    if (this.state.loginMethod === "password") {
      values["signinMethod"] = "Password";
    } else if (this.state.loginMethod?.includes("verificationCode")) {
      values["signinMethod"] = "Verification code";
    } else if (this.state.loginMethod === "webAuthn") {
      values["signinMethod"] = "WebAuthn";
    } else if (this.state.loginMethod === "ldap") {
      values["signinMethod"] = "LDAP";
    } else if (this.state.loginMethod === "faceId") {
      values["signinMethod"] = "Face ID";
    }
    const oAuthParams = Util.getOAuthGetParameters();

    values["type"] = oAuthParams?.responseType ?? this.state.type;

    if (oAuthParams?.samlRequest) {
      values["samlRequest"] = oAuthParams.samlRequest;
      values["type"] = "saml";
      values["relayState"] = oAuthParams.relayState;
    }
  }

  sendPopupData(message, redirectUri) {
    const params = new URLSearchParams(this.props.location.search);
    if (params.get("popup") === "1") {
      window.opener.postMessage(message, redirectUri);
    }
  }

  updateFormValues(field, value) {
    this.setState({ [field]: value });
  }

  setEmailOtpCode(otp) {
    this.updateFormValues("code", otp);
  }

  postCodeLoginAction(resp) {
    const application = this.getApplicationObj();
    const ths = this;
    const oAuthParams = localStorage.getItem("params") ? JSON.parse(atob(localStorage.getItem("params"))) : Util.getOAuthGetParameters();
    const code = resp.data;
    const concatChar = oAuthParams?.redirectUri?.includes("?") ? "&" : "?";
    const noRedirect = oAuthParams.noRedirect;
    const redirectUrl = `${oAuthParams.redirectUri}${concatChar}code=${code}&state=${oAuthParams.state}`;
    if (resp.data === RequiredMfa) {
      this.props.onLoginSuccess(window.location.href);
      return;
    }

    if (Setting.hasPromptPage(application)) {
      AuthBackend.getAccount()
        .then((res) => {
          if (res.status === "ok") {
            const account = res.data;
            account.organization = res.data2;
            this.onUpdateAccount(account);

            if (Setting.isPromptAnswered(account, application)) {
              Setting.goToLink(redirectUrl);
            } else {
              Setting.goToLinkSoft(ths, `/prompt/${application.name}?redirectUri=${oAuthParams.redirectUri}&code=${code}&state=${oAuthParams.state}`);
            }
          } else {
            Setting.showMessage("error", `${res.msg}`);
          }
        });
    } else {
      if (noRedirect === "true") {
        window.close();
        const newWindow = window.open(redirectUrl);
        if (newWindow) {
          setInterval(() => {
            if (!newWindow.closed) {
              newWindow.close();
            }
          }, 1000);
        }
      } else {
        Setting.goToLink(redirectUrl);
        this.sendPopupData({type: "loginSuccess", data: {code: code, state: oAuthParams.state}}, oAuthParams.redirectUri);
      }
    }
  }

  onFinish(values) {
    if (this.state.loginMethod === "webAuthn") {
      let username = this.state.username;
      if (username === null || username === "") {
        username = values["username"];
      }

      this.signInWithWebAuthn(username, values);
      return;
    }
    if (this.state.loginMethod === "faceId") {
      let username = this.state.username;
      if (username === null || username === "") {
        username = values["username"];
      }
      const application = this.getApplicationObj();
      fetch(`${Setting.ServerUrl}/api/faceid-signin-begin?owner=${application.organization}&name=${username}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Accept-Language": Setting.getAcceptLanguage(),
        },
      }).then(res => res.json())
        .then((res) => {
          if (res.status === "error") {
            Setting.showMessage("error", res.msg);
            return;
          }
          this.setState({
            openFaceRecognitionModal: true,
            values: values,
          });
        });
      return;
    }
    if (this.state.loginMethod === "password" || this.state.loginMethod === "ldap") {
      if (this.state.enableCaptchaModal === CaptchaRule.Always) {
        if (this.state.captchaType === "Default") {
          this.setState({
            openCaptchaModal: true,
            values: values,
          });
        } else {
          this.login(values);
        }
        return;
      } else if (this.state.enableCaptchaModal === CaptchaRule.Dynamic) {
        this.checkCaptchaStatus(values);
        return;
      }
    }
    this.login(values);
  }

  async login(values) {
    if (this.state.captchaType !== "") {
      values["captchaType"] = this.state.captchaType;
      values["captchaToken"] = this.state.captchaToken;
      values["clientSecret"] = this.state.clientSecret;
      if (this.state.captchaType === "reCAPTCHA v3-Standard") {
        values["captchaToken"] = await useGetRecaptchaV3Token(this.state.captchaSiteKey);
      }
    }
    // here we are supposed to determine whether Casdoor is working as an OAuth server or CAS server
    if (this.state.type === "cas") {
      // CAS
      const casParams = Util.getCasParameters();
      values["type"] = this.state.type;
      AuthBackend.loginCas(values, casParams).then((res) => {
        const loginHandler = (res) => {
          let msg = "Logged in successfully. ";
          if (casParams.service === "") {
            // If service was not specified, Casdoor must display a message notifying the client that it has successfully initiated a single sign-on session.
            msg += "Now you can visit apps protected by Casdoor.";
          }
          Setting.showMessage("success", msg);

          if (casParams.service !== "") {
            const st = res.data;
            const newUrl = new URL(casParams.service);
            newUrl.searchParams.append("ticket", st);
            window.location.href = newUrl.toString();
          }
        };

        if (res.status === "ok") {
          if (res.data === NextMfa) {
            this.setState({
              getVerifyTotp: () => {
                return (
                  <MfaAuthVerifyFormCustom
                    mfaProps={res.data2}
                    formValues={values}
                    authParams={casParams}
                    application={this.getApplicationObj()}
                    state={this.state}
                    setEmailOtpCode={this.setEmailOtpCodeRef}
                    updateFormValues={this.updateFormValuesRef}
                    onFail={() => {
                      Setting.showMessage("error", `${i18next.t("mfa:Verification failed")}:${res.msg}`);
                    }}
                    onSuccess={(res) => loginHandler(res)}
                  />);
              },
            });
          } else {
            loginHandler(res);
          }
        } else {
          Setting.showMessage("error", `${res.msg}`);
        }
      });
    } else {
      // OAuth
      // const oAuthParams = Util.getOAuthGetParameters();
      this.updateFormValues("submitLoading", true);
      this.populateOauthValues(values);
      const params = JSON.parse(atob(localStorage.getItem("params")));

      AuthBackend.login(values, params)
        .then((res) => {
          this.updateFormValues("submitLoading", false);
          const loginHandler = (res) => {
            const responseType = values["type"];
            if (responseType === "login") {
              Setting.showMessage("success", i18next.t("application:Logged in successfully"));
              this.props.onLoginSuccess();
            } else if (responseType === "code") {
              this.postCodeLoginAction(res);
            } else if (responseType === "token" || responseType === "id_token") {
              const amendatoryResponseType = responseType === "token" ? "access_token" : responseType;
              const accessToken = res.data;
              Setting.goToLink(`${params.redirectUri}#${amendatoryResponseType}=${accessToken}&state=${params.state}&token_type=bearer`);
            } else if (responseType === "saml") {
              if (res.data2.method === "POST") {
                this.setState({
                  samlResponse: res.data,
                  redirectUrl: res.data2.redirectUrl,
                  relayState: params.relayState,
                });
              } else {
                const SAMLResponse = res.data;
                const redirectUri = res.data2.redirectUrl;
                Setting.goToLink(`${redirectUri}?SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${params.relayState}`);
              }
            }
          };

          if (res.status === "ok") {
            if (res.data === NextMfa) {
              this.setState({
                getVerifyTotp: () => {
                  return (
                    <MfaAuthVerifyFormCustom
                      mfaProps={res.data2}
                      formValues={values}
                      authParams={params}
                      state={this.state}
                      setEmailOtpCode={this.setEmailOtpCodeRef}
                      application={this.getApplicationObj()}
                      updateFormValues={this.updateFormValuesRef}
                      onFail={() => {
                        Setting.showMessage("error", `${i18next.t("mfa:Verification failed")}:${res.msg}`);
                      }}
                      onSuccess={(res) => loginHandler(res)}
                    />);
                },
              });
            } else if (res.data === "SelectPlan") {
              // paid-user does not have active or pending subscription, go to application default pricing page to select-plan
              const pricing = res.data2;
              Setting.goToLink(`/select-plan/${pricing.owner}/${pricing.name}?user=${values.username}`);
            } else if (res.data === "BuyPlanResult") {
              // paid-user has pending subscription, go to buy-plan/result apge to notify payment result
              const sub = res.data2;
              Setting.goToLink(`/buy-plan/${sub.owner}/${sub.pricing}/result?subscription=${sub.name}`);
            } else {
              loginHandler(res);
            }
          } else {
            this.updateFormValues("captchaVerified", false);
            if (this.state.captchaType !== "reCAPTCHA v3-Standard") {
              window.grecaptcha?.reset();
            }
            Setting.showMessage("error", `${res.msg}`);
          }
        });
    }
  }

  isProviderVisible(providerItem) {
    if (this.state.mode === "signup") {
      return Setting.isProviderVisibleForSignUp(providerItem);
    } else {
      return Setting.isProviderVisibleForSignIn(providerItem);
    }
  }

  renderOtherFormProvider(application) {
    if (Setting.inIframe()) {
      return null;
    }

    for (const providerConf of application.providers) {
      if (providerConf.provider?.type === "Google" && providerConf.rule === "OneTap" && this.props.preview !== "auto") {
        return (
          <GoogleOneTapLoginVirtualButton application={application} providerConf={providerConf} />
        );
      }
    }

    return null;
  }

isFormValid() {
    let valid = true;
    if (!Setting.isValidEmail(this.state.username)) {
        valid = false;
    }

    if (!this.state?.password || this.state?.password?.length < 4) {
        valid = false;
    }

    return valid;
}

renderFormItem(application, signinItem) {
    if (!signinItem.visible && signinItem.name !== "Forgot password?") {
      return null;
    }

    if (signinItem.name === "Logo") {
      return (
        <>
            {
                signinItem.name === "Logo" &&
                <div className="login-logo-box">
                    <div dangerouslySetInnerHTML={createMarkup(signinItem.label)} />
                    {
                        Setting.renderHelmet(application)
                    }
                    {
                        Setting.renderLogo(application)
                    }
                    {
                        this.renderSignedInBox()
                    }
                </div>
            }
        </>
      );
    } else if (signinItem.name === "Back button") {
      return (
        <div>
          <div dangerouslySetInnerHTML={createMarkup(signinItem.label)} />
          {
            this.renderBackButton()
          }
        </div>
      );
    } else if (signinItem.name === "Languages") {
      const languages = application.organizationObj.languages;
      if (languages.length <= 1) {
        const language = (languages.length === 1) ? languages[0] : "en";
        if (Setting.getLanguage() !== language) {
          Setting.setLanguage(language);
        }
        return null;
      }

      return (
        <div className="login-languages">
          <div dangerouslySetInnerHTML={createMarkup(signinItem.label)} />
          <LanguageSelect languages={application.organizationObj.languages} />
        </div>
      );
    } else if (signinItem.name === "Signin methods") {
      return (
        <div>
          <div dangerouslySetInnerHTML={createMarkup(signinItem.label)} />
          {this.renderMethodChoiceBox()}
        </div>
      )
      ;
    } else if (signinItem.name === "Username") {
      return (
        <InputField
            label={signinItem.label}
            name="username"
            id="username"
            autoFocus
          />
      );
    } else if (signinItem.name === "Password") {
      return (
        <PasswordField
          label={signinItem.label}
          name="password"
          id="password"
        />
      );
    } else if (signinItem.name === "Forgot password?") {

      const handleChange = () => {
        this.setState({autoSignin: !this.state.autoSignin});
      };

      return (
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Checkbox checked={this.state.autoSignin} onChange={handleChange} name="autoSignin" id="autoSignin" /><span>{i18next.t("login:Remember me")}</span>
          </div>
          {
            signinItem.visible ? Setting.renderForgetLink(application, i18next.t("login:Forgot password?")) : null
          }
        </div>
      );
    } else if (signinItem.name === "Agreement") {
        return <Text className="text-center text-text-400">
            {i18next.t("agreementText", "By continuing, you’re agreeing to KrispCall’s")}&nbsp;
            <a
                href={BACK_LINKS.TOS}
                className="text-info"
                target={"_blank"} rel="noreferrer"
            >
                {i18next.t("termsText", "Terms of Service")}
            </a>,&nbsp;
            <a
                href={BACK_LINKS.POLICY}
                className="text-info"
                target={"_blank"} rel="noreferrer"
            >
                {i18next.t("privacyPolicy", "Privacy Policy")}
            </a> {i18next.t("and", "and")}&nbsp;
            <a
                href={BACK_LINKS.COOKIES_POLICIY}
                className="text-info"
                target={"_blank"} rel="noreferrer"
            >
                {i18next.t("cookiesPolicy", "Cookie Policy.")}
            </a>
        </Text>;
    //   return AgreementModal.isAgreementRequired(application) ? AgreementModal.renderAgreementFormItem(application, true, {}, this) : null;
    } else if (signinItem.name === "Login button") {
      const FORM_ID = "signin-form";

      const isValidForm = this.state.isValid && (
        (
          this.state.captchaVerified &&
          this.state.captchaToken !== ""
        ) ||
          this.state.captchaType === "reCAPTCHA v3-Standard"
          ||
          this.state.enableCaptchaModal === CaptchaRule.None
      );

      return (
        <div >
          <div dangerouslySetInnerHTML={createMarkup(signinItem.label)} />
          {
            this.renderCaptchaModal(application)
          }
          <Button
            disabled={!isValidForm}
            form={FORM_ID}
            htmlType="submit"
            variant="primary"
            className="w-full">
            {
              this.state.loginMethod === "webAuthn" ? i18next.t("login:Sign in with WebAuthn") :
                this.state.loginMethod === "faceId" ? i18next.t("login:Sign in with Face ID") :
                  i18next.t("login:Sign In")
            }
          </Button>
          {
            this.state.loginMethod === "faceId" ?
              <Suspense fallback={null}>
                <FaceRecognitionModal
                  visible={this.state.openFaceRecognitionModal}
                  onOk={(faceId) => {
                    const values = this.state.values;
                    values["faceId"] = faceId;

                    this.login(values);
                    this.setState({openFaceRecognitionModal: false});
                  }}
                  onCancel={() => this.setState({openFaceRecognitionModal: false})}
                />
              </Suspense>
              :
              <>
              </>
          }
        </div>
      );
    } else if (signinItem.name === "Providers") {
      const showForm = Setting.isPasswordEnabled(application) || Setting.isCodeSigninEnabled(application) || Setting.isWebAuthnEnabled(application) || Setting.isLdapEnabled(application);
      if (signinItem.rule === "None" || signinItem.rule === "") {
        signinItem.rule = showForm ? "small" : "big";
      }

      return (
        <div className="w-full">
          <div dangerouslySetInnerHTML={createMarkup(signinItem.label)} />
          <div className="or-section">
            <div /> <span>OR</span> <div />
          </div>
          <Form.Item className="w-full">
            {
              application.providers.filter(providerItem => this.isProviderVisible(providerItem)).map(providerItem => {
                return ProviderButtonCustom.renderProviderLogoCustom(providerItem.provider, application, "24px", "0", signinItem.rule, this.props.location);
              })
            }
            {
              this.renderOtherFormProvider(application)
            }
          </Form.Item>
        </div>
      );
    } else if (signinItem.name.startsWith("Text ") || signinItem?.isCustom) {
      return (
        <div dangerouslySetInnerHTML={createMarkup(signinItem.label)} />
      );
    } else if (signinItem.name === "Signup link") {
      const oAuthParams = localStorage.getItem("params") ? JSON.parse(atob(localStorage.getItem("params"))) : Util.getOAuthGetParameters();

      return (
        <div className="flex flex-col mb-6">
          <span className="text-text-600 text-2xl">Sign In</span>
          <span className="text-text-400 text-15">Don’t have an account yet?{" "}
           <Link
              to={`/signup/oauth/authorize?client_id=${oAuthParams?.clientId}&response_type=${oAuthParams?.responseType}&redirect_uri=${oAuthParams?.redirectUri}&scope=${oAuthParams?.scope}&state=${oAuthParams?.state}&code_challenge=${oAuthParams?.codeChallenge}&code_challenge_method=${oAuthParams?.challengeMethod}`}
              className="text-link-300"
              target={"_self"}
              >
              Sign Up
              </Link>
          </span>
        </div>
      );
    }
  }

  renderForm(application) {
    if (this.state.msg !== null) {
      return Util.renderMessage(this.state.msg);
    }

    if (this.state.mode === "signup" && !application.enableSignUp) {
      return (
        <Result
          status="error"
          title={i18next.t("application:Sign Up Error")}
          subTitle={i18next.t("application:The application does not allow to sign up new account")}
          extra={[
            <Button type="primary" key="signin"
              onClick={() => Setting.redirectToLoginPage(application, this.props.history)}>
              {
                i18next.t("login:Sign In")
              }
            </Button>,
          ]}
        >
        </Result>
      );
    }

    const showForm = Setting.isPasswordEnabled(application) || Setting.isCodeSigninEnabled(application) || Setting.isWebAuthnEnabled(application) || Setting.isLdapEnabled(application) || Setting.isFaceIdEnabled(application);
    if (showForm) {
      return (
        <>
          {
            application.signinItems?.map(signinItem => this.renderFormItem(application, signinItem))
          }
        </>
      );
    } else {
      return (
        <div style={{marginTop: "20px"}}>
          <div style={{fontSize: 16, textAlign: "left"}}>
            {i18next.t("login:To access")}&nbsp;
            <a target="_blank" rel="noreferrer" href={application.homepageUrl}>
              <span style={{fontWeight: "bold"}}>
                {application.displayName}
              </span>
            </a>
              :
          </div>
          <br />
          {
            application?.signinItems.map(signinItem => signinItem.name === "Providers" || signinItem.name === "Signup link" ? this.renderFormItem(application, signinItem) : null)
          }
        </div>
      );
    }
  }

  getCaptchaProviderItems(application) {
    const providers = application?.providers;

    if (providers === undefined || providers === null) {
      return null;
    }

    return providers.filter(providerItem => {
      if (providerItem.provider === undefined || providerItem.provider === null) {
        return false;
      }

      return providerItem.provider.category === "Captcha";
    });
  }

  renderCaptchaModal(application) {
    if (this.state.enableCaptchaModal === CaptchaRule.None) {
      return null;
    }
    const captchaProviderItems = this.getCaptchaProviderItems(application);
    const alwaysProviderItems = captchaProviderItems.filter(providerItem => providerItem.rule === "Always");
    const dynamicProviderItems = captchaProviderItems.filter(providerItem => providerItem.rule === "Dynamic");
    const provider = alwaysProviderItems.length > 0
      ? alwaysProviderItems[0].provider
      : dynamicProviderItems[0].provider;

    if (provider.type === "reCAPTCHA v3-Standard" || provider.type === "reCAPTCHA") {
      return <div className={provider.type === "reCAPTCHA v3-Standard" ? "" : "mb-6 h-20"}>
        <RecapthcaV2
          owner={provider.owner}
          name={provider.name}
          onOk={(captchaType, captchaToken, clientSecret, captchaSiteKey) => {
            this.updateFormValuesRef("captchaType", captchaType);
            this.updateFormValuesRef("captchaToken", captchaToken);
            this.updateFormValuesRef("clientSecret", clientSecret);
            this.updateFormValuesRef("captchaSiteKey", captchaSiteKey);
            this.updateFormValuesRef("captchaVerified", true);
          }}
          isCurrentProvider={true}
        />
      </div>;
    } else {
      return <CaptchaModalCustom
        owner={provider.owner}
        name={provider.name}
        visible={this.state.openCaptchaModal}
        onOk={(captchaType, captchaToken, clientSecret) => {
          this.updateFormValuesRef("captchaType", captchaType);
          this.updateFormValuesRef("captchaToken", captchaToken);
          this.updateFormValuesRef("clientSecret", clientSecret);
          this.updateFormValuesRef("captchaVerified", true);
          this.updateFormValuesRef("openCaptchaModal", false);
        }}
        onCancel={() => this.updateFormValuesRef("openCaptchaModal", false)}
        isCurrentProvider={true}
      />;
    }
  }

  renderFooter(application) {
    return (
      <div>
        {
          !application.enableSignUp ? null : (
            <React.Fragment>
              {i18next.t("login:No account?")}&nbsp;
              {
                Setting.renderSignupLink(application, i18next.t("login:sign up now"))
              }
            </React.Fragment>
          )
        }
      </div>
    );
  }

  sendSilentSigninData(data) {
    if (Setting.inIframe()) {
      const message = {tag: "Casdoor", type: "SilentSignin", data: data};
      window.parent.postMessage(message, "*");
    }
  }

  renderSignedInBox() {
    if (this.props.account === undefined || this.props.account === null) {
      this.sendSilentSigninData("user-not-logged-in");
      return null;
    }

    const application = this.getApplicationObj();
    if (this.props.account.owner !== application?.organization) {
      return null;
    }
    const values = {};
    values["application"] = application.name;
    // comment below only if we need to force login if we have session.
    // this.login(values);

    return (
      <div>
        <div style={{fontSize: 16, textAlign: "left"}}>
          {i18next.t("login:Continue with")}&nbsp;:
        </div>
        <br />
        <SelfLoginButton account={this.props.account} onClick={() => {
          const values = {};
          values["application"] = application.name;
          this.login(values);
        }} />
        <br />
        <br />
        <div style={{fontSize: 16, textAlign: "left"}}>
          {i18next.t("login:Or sign in with another account")}&nbsp;:
        </div>
      </div>
    );
  }

  signInWithWebAuthn(username, values) {
    const oAuthParams = Util.getOAuthGetParameters();
    this.populateOauthValues(values);
    const application = this.getApplicationObj();
    return fetch(`${Setting.ServerUrl}/api/webauthn/signin/begin?owner=${application.organization}&name=${username}`, {
      method: "GET",
      credentials: "include",
    })
      .then(res => res.json())
      .then((credentialRequestOptions) => {
        if ("status" in credentialRequestOptions) {
          Setting.showMessage("error", credentialRequestOptions.msg);
          throw credentialRequestOptions.status.msg;
        }

        credentialRequestOptions.publicKey.challenge = UserWebauthnBackend.webAuthnBufferDecode(credentialRequestOptions.publicKey.challenge);
        credentialRequestOptions.publicKey.allowCredentials.forEach(function(listItem) {
          listItem.id = UserWebauthnBackend.webAuthnBufferDecode(listItem.id);
        });

        return navigator.credentials.get({
          publicKey: credentialRequestOptions.publicKey,
        });
      })
      .then((assertion) => {
        const authData = assertion.response.authenticatorData;
        const clientDataJSON = assertion.response.clientDataJSON;
        const rawId = assertion.rawId;
        const sig = assertion.response.signature;
        const userHandle = assertion.response.userHandle;
        let finishUrl = `${Setting.ServerUrl}/api/webauthn/signin/finish?responseType=${values["type"]}`;
        if (values["type"] === "code") {
          finishUrl = `${Setting.ServerUrl}/api/webauthn/signin/finish?responseType=${values["type"]}&clientId=${oAuthParams.clientId}&scope=${oAuthParams.scope}&redirectUri=${oAuthParams.redirectUri}&nonce=${oAuthParams.nonce}&state=${oAuthParams.state}&codeChallenge=${oAuthParams.codeChallenge}&challengeMethod=${oAuthParams.challengeMethod}`;
        }
        return fetch(finishUrl, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            id: assertion.id,
            rawId: UserWebauthnBackend.webAuthnBufferEncode(rawId),
            type: assertion.type,
            response: {
              authenticatorData: UserWebauthnBackend.webAuthnBufferEncode(authData),
              clientDataJSON: UserWebauthnBackend.webAuthnBufferEncode(clientDataJSON),
              signature: UserWebauthnBackend.webAuthnBufferEncode(sig),
              userHandle: UserWebauthnBackend.webAuthnBufferEncode(userHandle),
            },
          }),
        })
          .then(res => res.json()).then((res) => {
            if (res.status === "ok") {
              const responseType = values["type"];
              if (responseType === "code") {
                this.postCodeLoginAction(res);
              } else if (responseType === "token" || responseType === "id_token") {
                const accessToken = res.data;
                Setting.goToLink(`${oAuthParams.redirectUri}#${responseType}=${accessToken}?state=${oAuthParams.state}&token_type=bearer`);
              } else {
                Setting.showMessage("success", i18next.t("login:Successfully logged in with WebAuthn credentials"));
                Setting.goToLink("/");
              }
            } else {
              Setting.showMessage("error", res.msg);
            }
          })
          .catch(error => {
            Setting.showMessage("error", `${i18next.t("general:Failed to connect to server")}${error}`);
          });
      });
  }

  renderPasswordOrCodeInput() {
    const application = this.getApplicationObj();
    if (this.state.loginMethod === "password" || this.state.loginMethod === "ldap") {
      return (
        <Col span={24}>
          <div className="login-password">
            <TopLabelInput
                name="password"
                type="password"
                label="Password"
                updateFormValues={this.updateFormValuesRef}
                placeholder={i18next.t("general:Password")}
                disabled={this.state.loginMethod === "password" ? !Setting.isPasswordEnabled(application) : !Setting.isLdapEnabled(application)}
                rules={[{required: true, message: i18next.t("login:Please input your password!")}]}
            />
          </div>
        </Col>
      );
    } else if (this.state.loginMethod?.includes("verificationCode")) {
      return (
        <Col span={24}>
          <div className="login-password">
            <Form.Item
              name="code"
              rules={[{required: true, message: i18next.t("login:Please input your code!")}]}
            >
              <SendCodeInput
                disabled={this.state.username?.length === 0 || !this.state.validEmailOrPhone}
                method={"login"}
                onButtonClickArgs={[this.state.username, this.state.validEmail ? "email" : "phone", Setting.getApplicationName(application)]}
                application={application}
              />
            </Form.Item>
          </div>
        </Col>
      );
    } else {
      return null;
    }
  }

  renderMethodChoiceBox() {
    const application = this.getApplicationObj();
    const items = [];

    const generateItemKey = (name, rule) => {
      return `${name}-${rule}`;
    };

    const itemsMap = new Map([
      [generateItemKey("Password", "All"), {label: i18next.t("general:Password"), key: "password"}],
      [generateItemKey("Password", "Non-LDAP"), {label: i18next.t("general:Password"), key: "password"}],
      [generateItemKey("Verification code", "All"), {label: i18next.t("login:Verification code"), key: "verificationCode"}],
      [generateItemKey("Verification code", "Email only"), {label: i18next.t("login:Verification code"), key: "verificationCodeEmail"}],
      [generateItemKey("Verification code", "Phone only"), {label: i18next.t("login:Verification code"), key: "verificationCodePhone"}],
      [generateItemKey("WebAuthn", "None"), {label: i18next.t("login:WebAuthn"), key: "webAuthn"}],
      [generateItemKey("LDAP", "None"), {label: i18next.t("login:LDAP"), key: "ldap"}],
      [generateItemKey("Face ID", "None"), {label: i18next.t("login:Face ID"), key: "faceId"}],
    ]);

    application?.signinMethods?.forEach((signinMethod) => {
      const item = itemsMap.get(generateItemKey(signinMethod.name, signinMethod.rule));
      if (item) {
        let label = signinMethod.name === signinMethod.displayName ? item.label : signinMethod.displayName;

        if (application?.signinMethods?.length >= 4 && label === "Verification code") {
          label = "Code";
        }

        items.push({label: label, key: item.key});
      }
    });

    if (items.length > 1) {
      return (
        <div>
          <Tabs className="signin-methods" items={items} size={"small"} defaultActiveKey={this.getDefaultLoginMethod(application)} onChange={(key) => {
            this.setState({loginMethod: key});
          }} centered>
          </Tabs>
        </div>
      );
    }
  }

  renderLoginPanel(application) {
    const orgChoiceMode = application.orgChoiceMode;

    if (this.isOrganizationChoiceBoxVisible(orgChoiceMode)) {
      return this.renderOrganizationChoiceBox(orgChoiceMode);
    }

    if (this.state.getVerifyTotp !== undefined) {
      return this.state.getVerifyTotp();
    } else {
      return (
        <React.Fragment>
          {/* {this.renderSignedInBox()} */}
          {this.renderForm(application)}
        </React.Fragment>
      );
    }
  }

  renderOrganizationChoiceBox(orgChoiceMode) {
    const renderChoiceBox = () => {
      switch (orgChoiceMode) {
      case "None":
        return null;
      case "Select":
        return (
          <div>
            <p style={{fontSize: "large"}}>
              {i18next.t("login:Please select an organization to sign in")}
            </p>
            <OrganizationSelect style={{width: "70%"}}
              onSelect={(value) => {
                Setting.goToLink(`/login/${value}?orgChoiceMode=None`);
              }} />
          </div>
        );
      case "Input":
        return (
          <div>
            <p style={{fontSize: "large"}}>
              {i18next.t("login:Please type an organization to sign in")}
            </p>
            <Form
              name="basic"
              onFinish={(values) => {Setting.goToLink(`/login/${values.organizationName}?orgChoiceMode=None`);}}
            >
              <Form.Item
                name="organizationName"
                rules={[{required: true, message: i18next.t("login:Please input your organization name!")}]}
              >
                <Input style={{width: "70%"}} onPressEnter={(e) => {
                  Setting.goToLink(`/login/${e.target.value}?orgChoiceMode=None`);
                }} />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                {i18next.t("general:Confirm")}
              </Button>
            </Form>
          </div>
        );
      default:
        return null;
      }
    };

    return (
      <div style={{height: 300, minWidth: 320}}>
        {renderChoiceBox()}
      </div>
    );
  }

  isOrganizationChoiceBoxVisible(orgChoiceMode) {
    if (this.state.orgChoiceMode === "None") {
      return false;
    }

    const path = this.props.match?.path;
    if (path === "/login" || path === "/login/:owner") {
      return orgChoiceMode === "Select" || orgChoiceMode === "Input";
    }

    return false;
  }

  renderBackButton() {
    if (this.state.orgChoiceMode === "None" || this.props.preview === "auto") {
      return (
        <Button type="text" size="large" icon={<ArrowLeftOutlined />}
          className="back-button"
          onClick={() => history.back()}>
        </Button>
      );
    }
  }

  render() {
    const application = this.getApplicationObj();
    if (application === undefined) {
      return null;
    }
    if (application === null) {
      return Util.renderMessageLarge(this, this.state.msg);
    }

    if (this.state.samlResponse !== "") {
      return <RedirectForm samlResponse={this.state.samlResponse} redirectUrl={this.state.redirectUrl} relayState={this.state.relayState} />;
    }

    if (application.signinHtml !== "") {
      return (
        <div dangerouslySetInnerHTML={createMarkup(application.signinHtml)} />
      );
    }

    const visibleOAuthProviderItems = (application.providers === null) ? [] : application.providers.filter(providerItem => this.isProviderVisible(providerItem));
    if (this.props.preview !== "auto" && !Setting.isPasswordEnabled(application) && !Setting.isCodeSigninEnabled(application) && !Setting.isWebAuthnEnabled(application) && !Setting.isLdapEnabled(application) && visibleOAuthProviderItems.length === 1) {
      Setting.goToLink(Provider.getAuthUrl(application, visibleOAuthProviderItems[0].provider, "signup"));
      return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
          <Spin size="large" tip={i18next.t("login:Signing in...")} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <CustomGithubCorner />
        <CustomThemeContextProvider>
          <div className="login-content" style={{margin: this.props.preview ?? this.parseOffset(application.formOffset)}}>
            {Setting.inIframe() || Setting.isMobile() ? null : <div dangerouslySetInnerHTML={createMarkup(application.formCss)} />}
            {Setting.inIframe() || !Setting.isMobile() ? null : <div dangerouslySetInnerHTML={createMarkup(application.formCssMobile)} />}
            <div className="login-panel">
               <div className="side-image">
                <SignupLeftWrapper>
                  {Setting.renderLogo(application)}
                  <div
                    className="side-image-container"
                    dangerouslySetInnerHTML={createMarkup(application.formSideHtml)}
                  />
                  <div className="flex flex-col mt-10">
                    <span className="signup-bottom-title">Connect with clients globally</span>
                    <span className="signup-bottom-sub-title">Join the future of cloud telephony for businesses</span>
                  </div>
                </SignupLeftWrapper>
              </div>
              <div className="login-form-container flex justify-center">
                <SigninFormWrapper
                  onFinish={this.onFinishRef}
                  setState={this.setStateRef}
                  application={application}
                  autoSignin={this.state.autoSignin}>
                  {
                    this.renderLoginPanel(application)
                  }
                </SigninFormWrapper>
              </div>
            </div>
          </div>
        </CustomThemeContextProvider>
        <CrispChat />
      </React.Fragment>
    );
  }
}

export default withRouter(SigninPageCustom);
