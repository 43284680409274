import { Controller } from "react-hook-form";
import OtpInput from "react-otp-input";
import { BiMinus } from "react-icons/bi";

import { useFieldError } from "src/krispcall/components/organisms/form";
import { InputField } from "src/krispcall/components/atoms/field/v3";

import { OtpInputWrapper } from "./styles";

const OtpInputField = props => {
  const {
    control,
    name,
    separator,
    numInputs = 6,
    value,
    containerStyle,
    placeholder,
    onPressEnter,
  } = props;

  // eslint-disable-next-line unused-imports/no-unused-vars
  const { fieldStatus, errorElement } = useFieldError({
    name: name,
  });

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <OtpInputWrapper className={containerStyle}>
              <OtpInput
                placeholder={placeholder}
                {...field}
                containerStyle={"otp-container gap-1.5"}
                inputStyle="otp-input"
                value={field.value ?? value}
                numInputs={numInputs}
                inputType="number"
                renderSeparator={
                  separator && (
                    <span>
                      <BiMinus size={20} className="otp-separator" />
                    </span>
                  )
                }
                renderInput={inputProps => <InputField onPressEnter={onPressEnter} status={fieldStatus} {...inputProps} />}
              />
              {errorElement}
              </OtpInputWrapper>
          );
        }}
      />
    </>

  );
};

export default OtpInputField;
