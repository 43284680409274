// Copied from SendCodeInput for modifications
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import OtpInputField from "src/krispcall/components/molecules/fields/v3/OtpInputField";
import Button from "src/krispcall/components/atoms/button/v3/Button";
import { formatTime } from "src/krispcall/utils/Setting";

import * as UserBackend from "src/backend/UserBackend";

import CaptchaModalCustom from "../../recaptcha/CaptchaModalCustom";

export const SendCodeInputCustom = ({ value, disabled, textBefore, onChange, onButtonClickArgs, application, method, countryCode, state, setEmailOtpCode, handleSubmit }) => {
  const [buttonLeftTime, setButtonLeftTime] = React.useState(0);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [visible, setVisible] = useState(false);
  const { watch } = useFormContext();
  const formValues = watch();
  let timer = undefined;

  const handleCountDown = (leftTime = 60) => {
    let leftTimeSecond = leftTime;
    setButtonLeftTime(leftTimeSecond);
    const countDown = () => {
      leftTimeSecond--;
      setButtonLeftTime(leftTimeSecond);
      if (leftTimeSecond === 0) {
        return;
      }
      timer = setTimeout(countDown, 1000);
    };
    timer = setTimeout(countDown, 1000);
  };

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  function handleOk(
    captchaType, captchaToken, clintSecret
  ) {
    setButtonLoading(true);
    UserBackend.sendCode(
      captchaType ? captchaType : "none", captchaToken, "***",
      method, countryCode, ...onButtonClickArgs).then(res => {
      setVisible(false);
      setButtonLoading(false);
      handleCountDown(60);
    });
  }

  useEffect(() => {
      handleOk();
  }, []);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <OtpInputField
        name="emailCode"
        id="emailCode"
        containerStyle="my-8"
        autoFocus
        placeholder="000000"
        onPressEnter={() => handleSubmit(formValues?.emailCode)}
      />
      <Button
        loading={buttonLoading}
        variant="primary"
        className="w-full mb-3.5"
        onClick={() => handleSubmit(formValues?.emailCode)}>
        Verify
      </Button>

      <Button disabled={buttonLeftTime > 0 || buttonLoading}
        onClick={() => setVisible(true)}
        variant="secondary"
        className="w-full mb-8">
        Resend Verification Code {buttonLeftTime > 0 ? `(${formatTime(buttonLeftTime)})` : ""}
      </Button>

      <CaptchaModalCustom
          owner={application.owner}
          name={application.name}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          isCurrentProvider={false}
          buttonLoading={buttonLoading}
        />

    </React.Fragment>
  );
};
