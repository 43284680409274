import { useFormContext } from "react-hook-form";

import { AuthenticatorApp } from "src/krispcall/assets";
import Button from "src/krispcall/components/atoms/button/v3/Button";
import OtpInputField from "src/krispcall/components/molecules/fields/v3/OtpInputField";

import { LoginVerificationWrapper } from "../Styles";

export const MfaVerifyTotpFormCustom = ({ mfaProps, onFinish, state, setAuthOtpCode, updateFormValues, loading }) => {
  // we will not render secret or qr from here. this section is moved to user>>profile>>edit. function removed
  // {renderSecret()}
  const { watch } = useFormContext();
  const formValues = watch();

  return (
  <LoginVerificationWrapper>
      <div className="signup-icon-circle"><img src={AuthenticatorApp} /></div>
        <div className="flex flex-col">
          <span className="login-verificaiton-title">2 step verification</span>
          <span className="login-verificaiton-subtitle">Please enter the 6-digit code you see in your authenticator app.</span>
      </div>

      <OtpInputField
        name="emailCode"
        id="emailCode"
        containerStyle="my-8"
        autoFocus
        placeholder="000000"
        onPressEnter={() => onFinish({passcode: formValues.emailCode})}
      />

      <Button
        loading={loading}
        block
        variant="primary"
        className="mb-8"
        onClick={() => onFinish({passcode: formValues.emailCode})}
      >
        Verify
      </Button>
    </LoginVerificationWrapper>
  );
};

export default MfaVerifyTotpFormCustom;
