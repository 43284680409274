import {Button} from "antd";
import i18next from "i18next";
import React, {useState} from "react";
import * as MfaBackend from "../../backend/MfaBackend";

export function MfaEnableForm({user, mfaType, secret, recoveryCodes, dest, countryCode, onSuccess, onFail}) {
  const [loading, setLoading] = useState(false);
  const requestEnableMfa = () => {
    const data = {
      mfaType,
      secret,
      recoveryCodes,
      dest,
      countryCode,
      ...user,
    };
    setLoading(true);
    MfaBackend.MfaSetupEnable(data).then(res => {
      if (res.status === "ok") {
        onSuccess(res);
      } else {
        onFail(res);
      }
    }
    ).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div style={{width: "400px", textAlign: "-webkit-center"}}>
      <p>{i18next.t("mfa:Please save these recovery code. Once your device cannot provide an authentication code, you can reset mfa authentication by using these recovery code")}</p>
      <br />
        <div style={{display: "flex", gap: "10px", width: "200px", flexWrap: "wrap"}}>
            {
                recoveryCodes.map((item, index) => (
                    <code key={index} style={{fontStyle: "solid", width: "90px"}}>{item}</code>
                ))
            }
        </div>
      <Button style={{marginTop: 24}} loading={loading} onClick={() => {
        requestEnableMfa();
      }} block type="primary">
        {i18next.t("general:Enable")}
      </Button>
    </div>
  );
}

export default MfaEnableForm;
